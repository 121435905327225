var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppLoadingSpinner',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("添加量表")]),_c('v-row',{staticClass:"mt-1 flex-nowrap"},[_c('v-autocomplete',{staticClass:"mx-6",attrs:{"filled":"","dense":"","clearable":"","chips":"","small-chips":"","deletable-chips":"","multiple":"","items":_vm.pickableLbCatItems,"item-text":"lbDispName","item-value":"guid"},on:{"change":_vm.resetPickItem},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.lbDispName)+" "),(!!item.timeboxSeconds)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"grey","small":""}},[_vm._v(" mdi-timer-outline ")]):_vm._e(),(!!item.isQuesShuffle)?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"grey","small":""}},[_vm._v(" mdi-shuffle-variant ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.selectedLbGuidList),callback:function ($$v) {_vm.selectedLbGuidList=$$v},expression:"selectedLbGuidList"}})],1)],1),_c('v-card',{staticClass:"mb-10",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" 拖动量表进行排序 "),_c('v-spacer')],1),_c('v-row',{staticClass:"mx-2 mb-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('draggable',{attrs:{"handle":".drag-handle","animation":"200"},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false},"change":_vm.rebuildOrderedPickedLbGuids},model:{value:(_vm.pickedLbItems),callback:function ($$v) {_vm.pickedLbItems=$$v},expression:"pickedLbItems"}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.isDragging ? 'flip-list' : null}},_vm._l((_vm.pickedLbItems),function(item){return _c('v-list-item',{key:item.guid},[_c('v-sheet',{staticClass:"my-2 px-4 d-flex justify-space-between",attrs:{"width":"100%","elevation":"2","tile":""}},[_c('v-icon',{staticClass:"drag-handle mr-4"},[_vm._v(" mdi-reorder-horizontal ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.lbDispName)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!!item.timeboxSeconds)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-timer-outline ")]):_vm._e()]}}],null,true)},[_vm._v(" 此量表设置了答题时间限制 ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!!item.isQuesShuffle)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-shuffle-variant ")]):_vm._e()]}}],null,true)},[_vm._v(" 此量表使用了“乱序答题”模式 ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)],1)}),1)],1)],1)],1)],1),_c('AppMessageBox',{attrs:{"title":"发生错误"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }