<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat>
      <v-card-title>添加量表</v-card-title>
      <v-row class="mt-1 flex-nowrap">
        <v-autocomplete
          class="mx-6"
          filled
          dense
          clearable
          chips
          small-chips
          deletable-chips
          multiple
          :items="pickableLbCatItems"
          item-text="lbDispName"
          item-value="guid"
          @change="resetPickItem"
          v-model="selectedLbGuidList"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.lbDispName }}
                <v-icon
                  v-if="!!item.timeboxSeconds"
                  class="ml-3"
                  color="grey"
                  small
                >
                  mdi-timer-outline
                </v-icon>
                <v-icon
                  v-if="!!item.isQuesShuffle"
                  class="ml-3"
                  color="grey"
                  small
                >
                  mdi-shuffle-variant
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-row>
    </v-card>
    <v-card flat class="mb-10">
      <v-card-title>
        拖动量表进行排序
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row class="mx-2 mb-6">
        <v-col cols="12">
          <draggable
            v-model="pickedLbItems"
            handle=".drag-handle"
            animation="200"
            @start="isDragging = true"
            @end="isDragging = false"
            @change="rebuildOrderedPickedLbGuids"
          >
            <transition-group
              type="transition"
              :name="!isDragging ? 'flip-list' : null"
            >
              <v-list-item v-for="item in pickedLbItems" :key="item.guid">
                <v-sheet
                  class="my-2 px-4 d-flex justify-space-between"
                  width="100%"
                  elevation="2"
                  tile
                >
                  <v-icon class="drag-handle mr-4">
                    mdi-reorder-horizontal
                  </v-icon>
                  <v-list-item-title>
                    {{ item.lbDispName }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="!!item.timeboxSeconds"
                          class="ml-3"
                          color="grey"
                          v-on="on"
                          v-bind="attrs"
                        >
                          mdi-timer-outline
                        </v-icon>
                      </template>
                      此量表设置了答题时间限制
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="!!item.isQuesShuffle"
                          class="ml-3"
                          color="grey"
                          v-on="on"
                          v-bind="attrs"
                        >
                          mdi-shuffle-variant
                        </v-icon>
                      </template>
                      此量表使用了“乱序答题”模式
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon color="white">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-sheet>
              </v-list-item>
            </transition-group>
          </draggable>
        </v-col>
      </v-row>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import _ from "lodash";
import { fetchNodePickedLbList } from "@/api/manageNode";

export default {
  components: {
    draggable,
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    value: {
      type: Array,
      required: true
    },
    nodeGuid: {
      type: String
    },
    useLbId: {
      type: Boolean,
      default: false
    },
    reorderable: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  data() {
    return {
      pickableLbCatItems: [],
      pickedLbItems: [],
      orderedPickedLbGuids: this.value,
      selectedLbGuidList: [],
      isDragging: false,
      isLoading: false,
      errorMsg: ""
    };
  },

  watch: {
    value(newVal) {
      this.orderedPickedLbGuids = newVal;
    },
    orderedPickedLbGuids(newVal) {
      this.$emit("change", newVal);
      if (!newVal || !newVal.length) {
        this.clearPickedItems();
      }
    },
    nodeGuid() {
      this.clearPickedItems();
      if (this.nodeGuid) {
        this.refreshLbItems();
      }
    }
  },

  methods: {
    rebuildOrderedPickedLbGuids() {
      this.orderedPickedLbGuids = this.pickedLbItems.map(item => item.guid);
    },
    resetPickItem(selectedLbGuids) {
      this.pickedLbItems = [];
      for (let lbGuid of selectedLbGuids) {
        this.pickedLbItems.push(
          this.pickableLbCatItems.find(lb => lb.guid === lbGuid)
        );
      }
      this.rebuildOrderedPickedLbGuids();
    },
    clearPickedItems() {
      this.selectedLbGuidList = [];
      this.pickedLbItems = [];
    },
    async refreshLbItems() {
      try {
        this.isLoading = true;
        let lbItems = [];
        if (this.nodeGuid) {
          lbItems = await fetchNodePickedLbList(this.nodeGuid);
        }
        // 排序，常用量表需要在最上面
        let categorizedLbItems = _.chain(lbItems)
          .sortBy(lb => (lb.isTopMost ? 0 : 1))
          .groupBy("categoryName")
          .value();
        let lbCatItems = [];
        for (let cat in categorizedLbItems) {
          lbCatItems.push({ divider: true });
          lbCatItems.push({ header: cat === "TOPMOST" ? "常用量表" : cat });
          lbCatItems.push(...categorizedLbItems[cat]);
        }
        this.pickableLbCatItems = lbCatItems;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.refreshLbItems();
  }
};
</script>

<style lang="scss" scoped>
.drag-handle {
  cursor: move;
}
</style>
