<template>
  <div>
    <AppDialog
      v-model="isShowDialog"
      persistent
      size="medium"
      overflow-height="calc(100vh - 270px)"
      title="新建个人测评"
      color="primary"
      :action-icon="
        currentStep === 1 ? 'mdi-arrow-right-bold' : 'mdi-check-bold'
      "
      :action-text="currentStep === 1 ? '下一步' : '完成'"
      @confirm="confirmClick"
      cancel-text="关闭"
    >
      <AppLoadingSpinner v-model="isLoading" />
      <div>
        <v-stepper v-model="currentStep" class="stepper-flat">
          <v-stepper-header class="stepper-flat">
            <v-stepper-step step="1" :complete="currentStep > 1">
              添加被试人员
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="currentStep > 2">
              添加量表
            </v-stepper-step>
          </v-stepper-header>
          <v-divider></v-divider>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card flat>
                <div class="d-flex justify-space-between align-center mb-n2">
                  <span class="text-h6">
                    搜索被试人员
                  </span>
                  <v-radio-group v-model="rdSelectedValue" row>
                    <v-radio
                      v-for="r in radios"
                      :label="r.text"
                      :key="r.value"
                      :value="r.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <v-autocomplete
                  v-model="pickedSearchPerson"
                  :items="personItems"
                  :loading="isAutoLoading"
                  :search-input.sync="search"
                  outlined
                  dense
                  chips
                  small-chips
                  clearable
                  hide-details
                  prepend-inner-icon="mdi-account-search"
                  item-text="searchText"
                  item-value="guid"
                  :label="`请输入${rdSelectedText}关键字...`"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        根据
                        <strong>{{ rdSelectedText }}</strong>
                        关键字查询
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>
                          <v-icon class="mr-1">{{ item.itemIcon }}</v-icon>
                          {{ item.searchText }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <div class="my-6 px-4">
                  <PersonInfoEditor
                    :is-displaying="isShowDialog"
                    :node-guids="nodeGuids"
                    :region-guid="regionGuid"
                    :is-creating-new="isCreatingNewPerson"
                    v-model="editingPersonInfo"
                    :no-update-fields="testPersonNoUpdateFields"
                    @update:no-update-fields="testPersonNoUpdateFields = $event"
                    :field-config-list="fieldConfigList"
                  />
                </div>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <AdminIndvLbPicker
                :node-guid="nodeGuid"
                v-model="orderedPickedLbGuids"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
      <template #action-ex>
        <v-btn
          v-show="currentStep > 1"
          text
          color="primary"
          @click="currentStep = 1"
        >
          <v-icon class="mr-2">mdi-arrow-left-bold</v-icon>
          上一步
        </v-btn>
      </template>
    </AppDialog>
    <AppDialog
      v-model="isShowGoToScaDialog"
      size="small"
      color="success"
      title="个人测评添加完成，是否直接进入答题界面？"
      action-text="直接开始答题"
      :loading="isDialogLoading"
      @confirm="goToScaPage"
    >
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>
<script>
import _ from "lodash";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import PersonInfoEditor from "@/components/PersonInfoEditor";
import AdminIndvLbPicker from "@/components/admin/AdminIndvLbPicker";
import AppDialog from "@/components/AppDialog";
import { fetchTestPersonList, fetchTestPersonDetails } from "@/api/person";
import { createOneNewIndvLbGroup } from "@/api/lbGroup";
import { simpleDeepCopy } from "@/utils/objectUtils";
import { mapGetters } from "vuex";
import { generateQrCode } from "@/api/qrCode";
import { getUserFieldConfig } from "@/api/fieldConfig";
import {
  buildLimitOffsetObj,
  buildOrderList
} from "@/utils/backendPagingUtils";
import {
  reInitPersonInfoForDispAndSave,
  reInitPersonInfoForEditing
} from "@/utils/personUtils";
import { indvTestPersonSearchType } from "@/utils/constants/lb";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AdminIndvLbPicker,
    PersonInfoEditor,
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    isIndividualType: {
      type: Boolean,
      required: true
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      errorMsg: "",
      orderedPickedLbGuids: [],
      currentStep: 1,
      isShowGoToScaDialog: false,
      lgGuid: "",
      nodeGuid: "",
      selectedLbGroupGuid: "",
      isShowDialog: false,
      isCreatingNewPerson: true,
      radios: Object.values(indvTestPersonSearchType),
      rdSelectedValue: "",
      rdSelectedText: "",
      isAutoLoading: false,
      pickedSearchPerson: "",
      newPersonGuidPlaceholder: "newGuid",
      personItems: [],
      search: "",
      editingPersonInfo: {},
      testPersonNoUpdateFields: [],
      fieldConfigList: []
    };
  },

  watch: {
    rdSelectedValue(newVal) {
      let selectedOption = _.find(this.radios, { value: newVal });
      this.rdSelectedText = selectedOption.text;
    },
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      this.resetPersonInfo(true);
      this.resetPersonItems();
      if (newVal) {
        // 开启dialog时，默认是新建
        this.isCreatingNewPerson = true;
      } else {
        this.$emit("closed");
        // 清空选中的人和量表
        this.pickedSearchPerson = "";
        this.orderedPickedLbGuids = [];
        this.currentStep = 1;
      }
    },
    async pickedSearchPerson(val) {
      if (val === this.newPersonGuidPlaceholder) {
        // 切换至新建 Person
        this.putInfoToNewPerson(this.search);
        this.pickedSearchPerson = "";
        this.isCreatingNewPerson = true;
      } else if (val) {
        // 切换至更新 Person
        await this.getPersonInfo(val);
        this.isCreatingNewPerson = false;
      }
    },
    search(val) {
      this.getActivePersonList(val);
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    isSearchLoginKey() {
      return this.rdSelectedValue === indvTestPersonSearchType.loginKey.value;
    },
    isSearchPersonName() {
      return this.rdSelectedValue === indvTestPersonSearchType.name.value;
    }
  },

  methods: {
    //防抖查询人员列表
    getActivePersonList: _.debounce(async function(val) {
      this.personItems = [];
      if (val) {
        this.isAutoLoading = true;
        let personList = await this.getTestPersonListFromFilter(val);
        this.personItems.push({
          guid: this.newPersonGuidPlaceholder,
          creatingNew: true,
          searchText: `新建被试人员 ${val}`,
          itemIcon: "mdi-account-plus"
        });
        if (personList.length) {
          let pList = personList.map(p => {
            let per = simpleDeepCopy(p.testPerson);
            per.creatingNew = false;
            per.searchText = `姓名：${per.name} 识别号：${per.loginIdentity.loginKey}`;
            per.itemIcon = "mdi-account";
            return per;
          });
          this.personItems.push(...pList);
        }
        this.isAutoLoading = false;
      }
    }, 500),
    //跳转到答题页面
    async goToScaPage() {
      this.isDialogLoading = true;
      let response = await generateQrCode({
        isIndividualType: this.isIndividualType,
        lbGroupGuid: this.lgGuid,
        qrTitle: this.editingPersonInfo.name,
        queryList: [
          {
            queryName: "indcode",
            queryValue: this.editingPersonInfo.password,
            isSecure: true
          }
        ]
      });
      this.isDialogLoading = false;
      this.isShowGoToScaDialog = false;
      window.open(response.url, "_blank");
    },
    //点击确认按钮的
    async confirmClick() {
      if (this.currentStep === 1) {
        this.currentStep = 2;
        if (!this.nodeGuid) {
          this.nodeGuid = this.nodeGuids[0];
        }
      } else {
        await this.createNewIndvLbGroup();
      }
    },
    async getTestPersonListFromFilter(userInput) {
      try {
        let response = await fetchTestPersonList(
          this.regionGuid,
          this.nodeGuids,
          this.buildPersonListFilterObj(userInput),
          buildLimitOffsetObj(1, 10),
          buildOrderList(["createdDate"], [true])
        );
        return response && response.personList ? response.personList : [];
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    /**
     1.如果选择了人员，那么只要保存个人测评，和人与测评的关系；
     2.如果是新增的人，那么要先保存个人信息，在保存个人测评，最后保存人与测评的关系
     */
    async createNewIndvLbGroup() {
      try {
        this.isLoading = true;
        reInitPersonInfoForDispAndSave(
          this.editingPersonInfo,
          this.fieldConfigList
        );
        let childData = this.orderedPickedLbGuids;
        this.lgGuid = await createOneNewIndvLbGroup(
          this.regionGuid,
          {
            ...this.editingPersonInfo
          },
          childData
        );
        // 创建完毕
        this.$emit("created", this.lgGuid);
        // 显示“直接开始答题”的弹窗
        this.isShowDialog = false;
        this.isShowGoToScaDialog = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    //获取人员信息
    async getPersonInfo(personGuid) {
      if (personGuid) {
        try {
          this.isLoading = true;
          let backendPerson = await fetchTestPersonDetails(personGuid);
          reInitPersonInfoForEditing(backendPerson, this.fieldConfigList);
          Object.assign(this.editingPersonInfo, backendPerson);
          this.newLbGroupName = this.editingPersonInfo.name;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    putInfoToNewPerson(userInput) {
      // 新建时，首先清空填入的值
      this.resetPersonInfo(false);
      if (this.isSearchLoginKey) {
        this.editingPersonInfo.loginIdentity.loginKey = userInput;
      } else if (this.isSearchPersonName) {
        this.editingPersonInfo.name = userInput;
      }
    },
    buildPersonListFilterObj(val) {
      let personInfoList = simpleDeepCopy(this.filterPersonInfoList || []);
      // 添加用户识别号的筛选
      if (this.isSearchLoginKey) {
        personInfoList.push({
          field: "loginIdentity",
          text: "",
          value: {
            loginKey: val
          }
        });
      } else if (this.isSearchPersonName) {
        personInfoList.push({
          field: "name",
          text: "",
          value: val
        });
      }
      return {
        personInfoList
      };
    },
    resetPersonItems() {
      this.personItems = [];
    },
    resetPersonInfo(includingLoginKeyType) {
      let resetLoginKeyType = {
        typeText: "",
        typeValue: ""
      };
      if (!includingLoginKeyType && this.editingPersonInfo.loginIdentity) {
        resetLoginKeyType = this.editingPersonInfo.loginIdentity.loginKeyType;
      }
      // 只需要填入注册绑定的字段
      this.editingPersonInfo = {
        loginIdentity: {
          loginKey: "",
          loginKeyType: resetLoginKeyType
        },
        hasPassword: false,
        password: "",
        name: "",
        gender: "",
        dateOfBirth: "",
        eduLevel: "",
        marriage: "",
        job: "",
        nodeGuid: "",
        nodeName: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: "",
        addInfo7: "",
        addInfo8: "",
        addInfo9: "",
        addInfo10: "",
        addInfo11: "",
        addInfo12: "",
        addInfo13: "",
        addInfo14: "",
        addInfo15: "",
        addInfo16: "",
        addInfo17: "",
        addInfo18: "",
        addInfo19: "",
        addInfo20: "",
        addInfo21: "",
        addInfo22: "",
        addInfo23: "",
        addInfo24: "",
        addInfo25: ""
      };
    },
    async fetchUserFieldConfigList() {
      try {
        this.fieldConfigList = await getUserFieldConfig(this.nodeGuids);
      } catch (err) {
        this.errorMsg = err.message;
      }
    }
  },
  async created() {
    this.rdSelectedValue = _.find(this.radios, { default: true }).value;
    this.rdSelectedText = _.find(this.radios, { default: true }).text;
    await this.fetchUserFieldConfigList();
  }
};
</script>

<style lang="scss">
.stepper-flat {
  box-shadow: unset !important;
}
</style>
