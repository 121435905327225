<template>
  <v-row>
    <v-col cols="11" sm="12">
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="currentSearch"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="currentSearch"
            :label="fieldAlias"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          format="24hr"
          v-model="currentSearch"
          full-width
          @click:minute="$refs.menu.save(currentSearch)"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    time: {
      type: String
    },
    fieldName: {
      type: String
    },
    fieldAlias: {
      type: String
    }
  },

  watch: {
    time(newVal) {
      this.currentSearch = newVal;
    },
    currentSearch(newVal) {
      this.$emit("change", [this.fieldName, newVal]);
    }
  },
  data() {
    return {
      currentSearch: this.time,
      menu2: false
    };
  }
};
</script>
