export const lbGroupType = {
  open: {
    value: "open",
    text: "开放测评"
  },
  group: {
    value: "group",
    text: "团体测评"
  },
  individual: {
    value: "individual",
    text: "个人测评"
  }
};

export const lbAlertAddState = [
  {
    value: "added",
    text: "已添加量表"
  },
  {
    value: "unadded",
    text: "未添加量表"
  },
  {
    value: "all",
    text: "全部显示"
  }
];

export const indvTestPersonSearchType = {
  loginKey: {
    value: "loginKey",
    text: "识别号",
    default: true
  },
  name: {
    value: "name",
    text: "姓名"
  }
};
