<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card class="fixed-card">
      <v-card-title ref="cardTitle">
        {{ `${typeText}管理` }}
        <AppTooltipBtn
          v-if="chargeShow('edit')"
          rounded
          btn-class="ml-8"
          color="primary"
          :icon="isGroupType ? 'mdi-calendar-plus' : 'mdi-earth-plus'"
          :label="`新建${typeText}`"
          @click="showAddDialog"
        />
        <AppTooltipBtn
          text
          btn-class="ml-4"
          color="primary"
          icon="mdi-refresh"
          label="刷新"
          tooltip="刷新列表"
          @click="refreshLbGroupList"
        />
        <v-spacer></v-spacer>
        <v-text-field
          class="lbgroup-search-field"
          v-model="lbGroupSearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-card-text v-show="!isHideWhenLoading">
        点击下方表格内容可直接更新
        <v-data-table
          class="mx-4"
          :height="getDataTableHeight()"
          :key="dataTableKey"
          :headers="nodeLbGroupHeaders"
          :items="lbGroupList"
          item-key="guid"
          :search="lbGroupSearchText"
          :loading="isLbGroupListLoading"
          :loading-text="`正在读取${typeText}列表，请稍候...`"
          :no-data-text="`未找到任何${typeText}`"
          :no-results-text="`未找到任何匹配的${typeText}`"
          fixed-header
          hide-default-footer
          disable-pagination
        >
          <!--=================== DataTable Header ==================-->
          <template v-slot:[`header.isShowScaReport`]="{ header }">
            {{ header.text }}
            <AppTooltipBtn
              color="grey"
              small-icon
              icon="mdi-help-circle-outline"
              :tooltip="header.tooltip"
            />
          </template>
          <template v-slot:[`header.isContinuousTest`]="{ header }">
            {{ header.text }}
            <AppTooltipBtn
              color="grey"
              small-icon
              icon="mdi-help-circle-outline"
              :tooltip="header.tooltip"
            />
          </template>
          <template v-slot:[`header.isAccountSingleAttempt`]="{ header }">
            {{ header.text }}
            <AppTooltipBtn
              color="grey"
              small-icon
              icon="mdi-help-circle-outline"
              :tooltip="header.tooltip"
            />
          </template>
          <!--=================== Item - lbGroupName ==================-->
          <template v-slot:[`item.lbGroupName`]="{ item }">
            <div v-if="!chargeShow('edit')">{{ item.lbGroupName }}</div>
            <v-edit-dialog
              v-else
              :return-value.sync="editingText"
              large
              persistent
              save-text="保存"
              cancel-text="取消"
              @open="editingTextOpen(item.lbGroupName)"
              @save="updateLbGroupName(item, editingText)"
              @cancel="editingCancel()"
            >
              <div>{{ item.lbGroupName }}</div>
              <template v-slot:input>
                <div class="my-4 text-h6">{{ `更新${typeText}名称` }}</div>
                <v-text-field
                  single-line
                  dense
                  autofocus
                  :label="`${typeText}名称`"
                  v-model="editingText"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <!--=================== Item - testPwd ==================-->
          <template v-slot:[`item.testPwd`]="{ item }">
            <div v-if="!chargeShow('edit')">{{ item.testPwd }}</div>
            <v-edit-dialog
              v-else
              :return-value.sync="editingText"
              large
              persistent
              save-text="保存"
              cancel-text="取消"
              @open="editingTextOpen(item.testPwd)"
              @save="updateLbGroupTestPwd(item, editingText)"
              @cancel="editingCancel()"
            >
              <div>{{ item.testPwd }}</div>
              <template v-slot:input>
                <div class="my-4 text-h6">更新测量码</div>
                <v-text-field
                  single-line
                  dense
                  autofocus
                  label="测量码"
                  v-model="editingText"
                  :loading="isTestPwdLoading"
                  append-icon="mdi-refresh-circle"
                  @click:append="generateNewTestPwd"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <!--=================== Item - dateRange ==================-->
          <template v-slot:[`item.beginDate`]="{ item }">
            <div v-if="!chargeShow('edit')">
              {{ getDateRangeString(item.beginDate, item.endDate) }}
            </div>
            <v-edit-dialog
              v-else
              :return-value.sync="editingDateRange"
              large
              persistent
              save-text="保存"
              cancel-text="取消"
              @open="editingDateRangeOpen(item.beginDate, item.endDate)"
              @save="updateLbGroupDateRange(item, editingDateRange)"
              @cancel="editingCancel()"
            >
              <div>
                {{ getDateRangeString(item.beginDate, item.endDate) }}
              </div>
              <template v-slot:input>
                <div class="my-4 text-h6">点击更新测评开放日期</div>
                <AppDateRangePicker
                  single-line
                  :clearable="false"
                  label="测评日期"
                  is-future-date
                  v-model="editingDateRange"
                />
              </template>
            </v-edit-dialog>
          </template>
          <!--=================== Item - Switches ==================-->
          <template v-slot:[`item.isShowScaReport`]="{ item }">
            <v-switch
              :disabled="!chargeShow('edit')"
              inset
              :ripple="false"
              v-model="item.isShowScaReport"
              @change="
                updateIsShowScaReportForLbGroup(
                  item.guid,
                  item.isShowScaReport,
                  false
                )
              "
            ></v-switch>
          </template>
          <template v-slot:[`item.isContinuousTest`]="{ item }">
            <v-switch
              :disabled="!chargeShow('edit')"
              inset
              :ripple="false"
              v-model="item.isContinuousTest"
              @change="
                updateIsContinuousTestForLbGroup(
                  item.guid,
                  item.isContinuousTest
                )
              "
            ></v-switch>
          </template>
          <template v-slot:[`item.isAccountSingleAttempt`]="{ item }">
            <v-switch
              :disabled="!chargeShow('edit')"
              inset
              :ripple="false"
              v-model="item.isAccountSingleAttempt"
              @change="
                updateIsAccountSingleAttemptForLbGroup(
                  item.guid,
                  item.isAccountSingleAttempt
                )
              "
            ></v-switch>
          </template>
          <!--=================== Item - Complete Progress ==================-->
          <template v-slot:[`item.assignLbCount`]="{ value }">
            <v-chip label>
              <v-icon left small class="ml-1 mr-2">
                mdi-file-document-edit
              </v-icon>
              {{ value }}
            </v-chip>
          </template>
          <template v-slot:[`item.assignPersonCount`]="{ value }">
            <v-chip label>
              <v-icon left small class="ml-1 mr-2">mdi-account-multiple</v-icon>
              {{ value }}
            </v-chip>
          </template>
          <template v-slot:[`item.completeProgress`]="{ item, value }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- 完成度为 完成的总数 / (分配量表数 * 分配人数) -->
                <v-progress-linear
                  v-if="!isNaN(value)"
                  :value="value * 100"
                  color="primary"
                  rounded
                  height="20"
                  v-on="on"
                  v-bind="attrs"
                >
                  <template v-slot:default="{ value }">
                    <strong>{{ Math.floor(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </template>
              <span>
                {{
                  `进度：${item.completedLbCount}/${item.assignLbCount *
                  item.assignPersonCount}`
                }}
              </span>
            </v-tooltip>
          </template>
          <!--=================== Item - Actions ==================-->
          <template v-slot:[`item.guid`]="{ item }">
            <AppTooltipBtn
              v-if="
                (isGroupType || isIndividualType) &&
                  !isNaN(item.completeProgress)
              "
              btn-class="mr-2"
              color="primary"
              icon="mdi-chart-box-outline"
              tooltip="测评详情"
              @click="showLbGroupMonitorDialog(item)"
            />
            <AppTooltipBtn
              v-if="chargeShow('edit') && isGroupType"
              btn-class="mr-2"
              color="primary"
              icon="mdi-account-multiple-plus"
              tooltip="配置人员"
              @click="showAssignPersonDialog(item)"
            />
            <AppTooltipBtn
              v-if="chargeShow('edit')"
              btn-class="mr-2"
              color="primary"
              icon="mdi-playlist-plus"
              tooltip="配置量表"
              @click="showLbPickerDialog(item)"
            />
            <AppTooltipBtn
              v-if="isOpenType || isIndividualType"
              btn-class="mr-2"
              color="primary"
              icon="mdi-qrcode"
              tooltip="生成二维码"
              @click="showQrCodeDialog(item, item.testPwd)"
            />
            <AppTooltipBtn
              v-if="chargeShow('edit')"
              btn-class="mr-2"
              color="primary"
              icon="mdi-pencil"
              tooltip="其他设置"
              @click="showOtherInfoEditDialog(item)"
            />
            <AppTooltipBtn
              v-if="chargeShow('edit')"
              btn-class="mr-2"
              color="error"
              icon="mdi-delete"
              :tooltip="`删除此${typeText}`"
              @click="showDeleteLbGroupDialog(item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isShowLbGroupCreationDialog"
      size="small"
      :title="`添加新的${typeText}`"
      color="green"
      action-text="创建"
      :loading="isDialogLoading"
      @confirm="createNewLbGroup"
      @closed="groupCreationDialogClosed"
    >
      <v-select
        :items="allNodeList"
        v-model="selectedNodeGuid"
        :label="`${typeText}所在的单位`"
        :rules="fieldRules.nodeRequired"
      ></v-select>
      <v-text-field
        v-model="newLbGroupName"
        :label="`${typeText}名称`"
        :rules="fieldRules.newLbGroupName"
      ></v-text-field>
      <v-select
        :items="presetDateRangeItems"
        v-model="selectedPresetDateRange"
        label="测评开放时间"
      ></v-select>
    </AppDialog>
    <CreateIndividualTest
      v-model="isShowIndividualCreationDialog"
      size="big"
      :title="`添加新的${typeText}`"
      color="green"
      action-text="创建"
      :loading="isDialogLoading"
      @created="indvLbGroupCreated"
      :is-individual-type="isIndividualType"
    />
    <AdminLbGroupMonitorDialog
      v-model="isShowLbGroupMonitorDialog"
      :region-guid="regionGuid"
      :node-guid="selectedLbGroupNodeGuid"
      :lb-group-guid="selectedLbGroupGuid"
      :lb-group-name="selectedLbGroupName"
      @closed="lbGroupMonitorDialogClosed"
    />
    <AdminLbGroupAssignPersonDialog
      v-model="isShowAssignPersonDialog"
      :region-guid="regionGuid"
      :node-guid="selectedLbGroupNodeGuid"
      :lb-group-guid="selectedLbGroupGuid"
      :lb-group-name="selectedLbGroupName"
      @closed="assignPersonDialogClosed"
    />
    <AppDialog
      v-model="isShowLbPickerDialog"
      persistent
      size="large"
      overflow-height="calc(100vh - 270px)"
      title="关联量表设置"
      cancel-text="关闭"
      @closed="lbPickerDialogClosed"
    >
      <AdminConfigLbPicker :lb-group-guid="selectedLbGroupGuid" />
    </AppDialog>
    <AppDialog
      v-model="isShowQrCodeDialog"
      persistent
      color="primary"
      title="测量二维码"
      action-text="下载"
      action-icon="mdi-download"
      cancel-text="关闭"
      @confirm="downloadQrImg"
      @closed="qrCodeDialogClosed"
    >
      <a :href="qrCodeUrl" target="blank">
        <img width="100%" :src="qrCodeImgSourceUrl" />
      </a>
    </AppDialog>
    <AppDialog
      v-model="isShowScaReportConfirmDialog"
      color="orange"
      size="small"
      persistent
      title="确定要开启“显示报告”，让被试人员答题后能够直接看到报告吗？"
      action-icon="mdi-alert"
      action-text="确认开启"
      :loading="isDialogLoading"
      @confirm="
        updateIsShowScaReportForLbGroup(
          selectedLbGroupGuid,
          selectedIsShowScaReport,
          true
        )
      "
      @cancelled="scaReportConfirmDialogCancelled"
      @closed="scaReportConfirmDialogClosed"
    >
      开启前，请确认被试人员有没有自我解读报告的能力！
    </AppDialog>
    <AppDialog
      v-model="isShowOtherInfoEditDialog"
      color="primary"
      persistent
      title="其他设置"
      action-icon="mdi-check"
      action-text="确定"
      :loading="isDialogLoading"
      @confirm="editOtherInfoConfirmed"
      @closed="otherInfoEditDialogClosed"
    >
      <v-textarea
        filled
        dense
        label="测评的描述和说明信息"
        v-model="editingLbGroupDesc"
        rows="1"
        auto-grow
        :counter="180"
        :rules="[fieldRules.textMaxLength(180)]"
      ></v-textarea>
      <v-textarea
        filled
        dense
        label="用户完成所有答题后的提示信息 (不填则显示默认值)"
        v-model="editingAllCompletedMsg"
        rows="1"
        auto-grow
        :counter="180"
        :rules="[fieldRules.textMaxLength(180)]"
      ></v-textarea>
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteLbGroupDialog"
      color="error"
      size="small"
      :title="`是否确定要删除量表套餐 ${selectedLbGroupName} ？`"
      action-text="确认删除"
      :loading="isDialogLoading"
      @confirm="deleteOneLbGroup"
      @closed="deleteLbGroupDialogClosed"
    >
      删除后不可恢复
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import CreateIndividualTest from "@/components/CreateIndividualTest";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import AdminLbGroupMonitorDialog from "@/components/admin/AdminLbGroupMonitorDialog";
import AdminLbGroupAssignPersonDialog from "@/components/admin/AdminLbGroupAssignPersonDialog";
import AdminConfigLbPicker from "@/components/admin/AdminConfigLbPicker";
import { mapGetters } from "vuex";
import {
  fetchLbGroupList,
  fetchLbGroupDetails,
  autoGenerateTestPwd,
  createOneNewLbGroup,
  updateLbGroupName,
  updateLbGroupTestPwd,
  updateIsShowScaReportForLbGroup,
  updateIsContinuousTestForLbGroup,
  updateIsAccountSingleAttemptForLbGroup,
  updateBeginEndDateForLbGroup,
  deleteOneLbGroup,
  updateOtherInfoForLbGroup
} from "@/api/lbGroup";
import { generateQrCode } from "@/api/qrCode";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { downloadFileUrl } from "@/utils/download";
import {
  presetFutureDateRangeSelects,
  getFutureStartEndDateFromPresetRange,
  getDateFromDateTime,
  getStartDateFromDateRange,
  getEndDateFromDateRange
} from "@/utils/dateTime";
import { lbGroupType } from "@/utils/constants/lb";
import { lbGroupPagePermission } from "@/api/permission";

export default {
  components: {
    CreateIndividualTest,
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppDialog,
    AppDateRangePicker,
    AdminConfigLbPicker,
    AdminLbGroupMonitorDialog,
    AdminLbGroupAssignPersonDialog
  },

  props: {
    type: {
      type: String
    }
  },

  data() {
    return {
      model: [],
      isLoading: false,
      isDialogLoading: false,
      isHideWhenLoading: false,
      isLbGroupListLoading: false,
      isTestPwdLoading: false,
      errorMsg: "",
      dataTableKey: 0,
      nodeLbGroupHeaders: [],
      lbGroupList: [],
      allNodeList: [],
      lbGroupSearchText: "",
      selectedLbGroupGuid: "",
      selectedLbGroupName: "",
      selectedLbGroupNodeGuid: "",
      selectedIsShowScaReport: false,
      editingLbGroupDesc: "",
      editingAllCompletedMsg: "",
      qrCodeImgSourceUrl: "",
      qrCodeUrl: "",
      scaAllCompletedMsg: "",
      // create new lb group
      presetDateRangeItems: presetFutureDateRangeSelects,
      selectedPresetDateRange: presetFutureDateRangeSelects.find(
        item => item.default
      ).value,
      selectedNodeGuid: "",
      newLbGroupName: "",
      // datetable editor
      editingText: "",
      editingDateRange: [],
      // dialogs
      isShowLbGroupCreationDialog: false,
      isShowIndividualCreationDialog: false,
      isShowLbGroupMonitorDialog: false,
      isShowAssignPersonDialog: false,
      isShowLbPickerDialog: false,
      isShowQrCodeDialog: false,
      isShowScaReportConfirmDialog: false,
      isShowOtherInfoEditDialog: false,
      isShowDeleteLbGroupDialog: false,
      // field rules
      fieldRules: {
        nodeRequired: [
          val => (val || "").length > 0 || "请填写此次测评所在的测评单位"
        ],
        newLbGroupName: [val => (val || "").length > 0 || "名称不能为空"],
        textMaxLength: len => val => (val || "").length <= len || "超过最大长度"
      }
    };
  },

  watch: {
    async type() {
      this.isLoading = true;
      this.isHideWhenLoading = true;
      this.initNodeLbGroupHeaders();
      await this.prepareNodeList();
      await this.refreshLbGroupList();
      this.isHideWhenLoading = false;
      this.isLoading = false;
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    isGroupType() {
      return this.type === lbGroupType.group.value;
    },
    isOpenType() {
      return this.type === lbGroupType.open.value;
    },
    isIndividualType() {
      return this.type === lbGroupType.individual.value;
    },
    typeText() {
      return lbGroupType[this.type].text;
    }
  },

  methods: {
    chargeShow(permission) {
      let lbType = "";
      let perms = "";
      switch (this.type) {
        case "group":
          lbType = "group_sca_all";
          perms = "group_sca_" + permission;
          break;
        case "open":
          lbType = "open_sca_all";
          perms = "open_sca_" + permission;
          break;
        case "individual":
          lbType = "indv_sca_all";
          perms = "indv_sca_" + permission;
          break;
        default:
          break;
      }
      return lbGroupPagePermission(lbType, perms);
    },
    initNodeLbGroupHeaders() {
      this.nodeLbGroupHeaders = [
        {
          text: `${this.typeText}名称`,
          value: "lbGroupName"
        }
      ];
      if (this.isOpenType) {
        this.nodeLbGroupHeaders.push({
          text: "测量码",
          value: "testPwd"
        });
      }
      this.nodeLbGroupHeaders.push({
        text: "测评单位",
        value: "manageNodeDetails.name"
      });
      if (this.isIndividualType) {
        this.nodeLbGroupHeaders.push({
          text: "姓名",
          value: "testPersonName"
        });
      }
      this.nodeLbGroupHeaders.push(
        {
          text: "测评开放日期",
          value: "beginDate",
          sort: (d1, d2) => {
            if (!d1) return 1;
            if (!d2) return -1;
            return new Date(d1) > new Date(d2) ? 1 : -1;
          },
          filterable: false
        },
        {
          text: "显示报告",
          value: "isShowScaReport",
          tooltip: "被试者测试完成后，是否能直接查看报告",
          filterable: false
        }
      );
      if (this.isOpenType) {
        this.nodeLbGroupHeaders.push(
          {
            text: "连续测试",
            value: "isContinuousTest",
            tooltip:
              "连续答题模式（开启：被试者必须按序做完全部关联的量表。关闭：被试者可自行选择一个量表进行测量。）"
          },
          {
            text: "账户测评限制",
            value: "isAccountSingleAttempt",
            tooltip:
              "开启后，每个账户（被试者识别号）只能进行一次开放测评，测评完成后再次扫码将无法重复进行测评。"
          }
        );
      }
      this.nodeLbGroupHeaders.push({
        text: "分配量表",
        value: "assignLbCount",
        filterable: false
      });
      if (this.isGroupType) {
        this.nodeLbGroupHeaders.push(
          {
            text: "分配人数",
            value: "assignPersonCount",
            filterable: false
          },
          {
            text: "完成进度",
            value: "completeProgress",
            sort: (prog1, prog2) => {
              if (isNaN(prog1)) return -1;
              if (isNaN(prog2)) return 1;
              return prog1 > prog2 ? 1 : -1;
            },
            filterable: false
          }
        );
      }
      if (this.isIndividualType) {
        this.nodeLbGroupHeaders.push({
          text: "完成进度",
          value: "completeProgress",
          sort: (prog1, prog2) => {
            if (isNaN(prog1)) return -1;
            if (isNaN(prog2)) return 1;
            return prog1 > prog2 ? 1 : -1;
          },
          filterable: false
        });
      }
      this.nodeLbGroupHeaders.push({
        text: "操作",
        value: "guid",
        sortable: false,
        filterable: false
      });
    },
    assignLbGroupItem(lbGroupItem) {
      this.selectedLbGroupGuid = lbGroupItem.guid;
      this.selectedLbGroupName = lbGroupItem.lbGroupName;
      this.selectedLbGroupNodeGuid = lbGroupItem.manageNodeDetails.guid;
    },
    resetLbGroupItem() {
      this.selectedLbGroupGuid = "";
      this.selectedLbGroupName = "";
      this.selectedLbGroupNodeGuid = "";
    },
    // ==================== Update datatable directly =========================
    async unshiftItemToLbGroupList(newGuid) {
      this.isLoading = true;
      // 新建的 LbGroup 添加在最前面
      let newLbGroup = await this.getLbGroupDetails(newGuid);
      this.lbGroupList.unshift(newLbGroup);
      this.isLoading = false;
    },
    spliceItemFromLbGroupList(deletedGuid) {
      let delIndex = this.lbGroupList.findIndex(lg => lg.guid === deletedGuid);
      this.lbGroupList.splice(delIndex, 1);
    },
    async updateItemFromLbGroupList(updatedGuid) {
      let delIndex = this.lbGroupList.findIndex(lg => lg.guid === updatedGuid);
      this.isLoading = true;
      let newLbGroup = await this.getLbGroupDetails(updatedGuid);
      this.lbGroupList.splice(delIndex, 1, newLbGroup);
      this.isLoading = false;
    },
    // ==================== Refresh datatable =========================
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      let overflowHeight = 160 + this.getCardTitleHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    async prepareNodeList() {
      try {
        this.allNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async refreshLbGroupList() {
      try {
        this.isLbGroupListLoading = true;
        this.lbGroupList = await fetchLbGroupList(
          this.regionGuid,
          this.nodeGuids,
          this.type,
          true
        );
        // 刷新 dataTable 控件的显示
        this.dataTableKey++;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLbGroupListLoading = false;
    },
    async getLbGroupDetails(lbGroupGuid) {
      try {
        return await fetchLbGroupDetails(lbGroupGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    // ==================== Lb Creator =========================
    async createNewLbGroup() {
      try {
        this.isDialogLoading = true;
        let startEndDate = getFutureStartEndDateFromPresetRange(
          this.selectedPresetDateRange
        );
        let newGuid = await createOneNewLbGroup(
          this.regionGuid,
          this.selectedNodeGuid,
          this.type,
          this.newLbGroupName,
          startEndDate.startDate,
          startEndDate.endDate
        );
        await this.unshiftItemToLbGroupList(newGuid);
        this.isShowLbGroupCreationDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    groupCreationDialogClosed() {
      // 重置控件
      this.selectedNodeGuid = "";
      this.newLbGroupName = "";
    },
    async indvLbGroupCreated(newLbGroupGuid) {
      await this.unshiftItemToLbGroupList(newLbGroupGuid);
    },
    // ==================== Lb Group Table =========================
    async updateIsContinuousTestForLbGroup(lbGroupGuid, isContinuousTest) {
      try {
        this.isLoading = true;
        await updateIsContinuousTestForLbGroup(lbGroupGuid, isContinuousTest);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async updateIsAccountSingleAttemptForLbGroup(
      lbGroupGuid,
      isAccountSingleAttempt
    ) {
      try {
        this.isLoading = true;
        await updateIsAccountSingleAttemptForLbGroup(
          lbGroupGuid,
          isAccountSingleAttempt
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ==================== Update Lb Group Table =========================
    editingTextOpen(originalText) {
      this.editingText = originalText;
    },
    editingDateRangeOpen(beginDate, endDate) {
      if (beginDate && endDate) {
        this.editingDateRange = [beginDate, endDate];
      } else {
        this.editingDateRange = [];
      }
    },
    editingCancel() {
      this.editingText = "";
      this.editingDateRange = [];
    },
    async updateLbGroupName(lbGroupItem, newLbGroupName) {
      try {
        this.isLoading = true;
        await updateLbGroupName(lbGroupItem.guid, newLbGroupName);
        lbGroupItem.lbGroupName = newLbGroupName;
        this.editingText = "";
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async generateNewTestPwd() {
      try {
        this.isTestPwdLoading = true;
        this.editingText = await autoGenerateTestPwd(this.regionGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isTestPwdLoading = false;
    },
    async updateLbGroupTestPwd(lbGroupItem, newTestPwd) {
      try {
        this.isLoading = true;
        if (lbGroupItem.testPwd !== newTestPwd) {
          await updateLbGroupTestPwd(lbGroupItem.guid, newTestPwd);
          lbGroupItem.testPwd = newTestPwd;
        }
        this.editingText = "";
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    getDateRangeString(isoBeginDate, isoEndDate) {
      if (isoBeginDate && isoEndDate) {
        return `${getDateFromDateTime(isoBeginDate)} ~ ${getDateFromDateTime(
          isoEndDate
        )}`;
      }
      let dateRangeAll = presetFutureDateRangeSelects.find(
        item => item.value === "all"
      );
      return dateRangeAll ? dateRangeAll.text : "";
    },
    async updateLbGroupDateRange(lbGroupItem, newDateRange) {
      try {
        this.isLoading = true;
        let beginDate = getStartDateFromDateRange(newDateRange);
        let endDate = getEndDateFromDateRange(newDateRange);
        await updateBeginEndDateForLbGroup(lbGroupItem.guid, {
          beginDate,
          endDate
        });
        lbGroupItem.beginDate = beginDate;
        lbGroupItem.endDate = endDate;
        this.editingDateRange = [];
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ==================== Lb Group Monitor Details =========================
    showLbGroupMonitorDialog(lbGroupItem) {
      this.assignLbGroupItem(lbGroupItem);
      this.isShowLbGroupMonitorDialog = true;
    },
    lbGroupMonitorDialogClosed() {
      this.updateItemFromLbGroupList(this.selectedLbGroupGuid);
      this.resetLbGroupItem();
    },
    // ==================== Assign Person to Lb Group =========================
    showAssignPersonDialog(lbGroupItem) {
      this.assignLbGroupItem(lbGroupItem);
      this.isShowAssignPersonDialog = true;
    },
    assignPersonDialogClosed() {
      this.updateItemFromLbGroupList(this.selectedLbGroupGuid);
      this.resetLbGroupItem();
    },
    // ==================== Assign Lb to Lb Group =========================
    showLbPickerDialog(lbGroupItem) {
      this.assignLbGroupItem(lbGroupItem);
      this.isShowLbPickerDialog = true;
    },
    lbPickerDialogClosed() {
      this.updateItemFromLbGroupList(this.selectedLbGroupGuid);
      this.resetLbGroupItem();
    },
    // ==================== Qr Code Display =========================
    async showQrCodeDialog(lbGroupItem, testPwd) {
      this.assignLbGroupItem(lbGroupItem);
      try {
        this.isLoading = true;
        let response = await generateQrCode({
          isIndividualType: this.isIndividualType,
          lbGroupGuid: this.selectedLbGroupGuid,
          qrTitle: this.selectedLbGroupName,
          queryList: [{ queryName: "cd", queryValue: testPwd, isSecure: true }]
        });
        this.qrCodeImgSourceUrl = response.source;
        this.qrCodeUrl = response.url;
        this.isShowQrCodeDialog = true;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    downloadQrImg() {
      downloadFileUrl(
        this.qrCodeImgSourceUrl,
        `测量二维码_${this.selectedLbGroupName}.jpg`
      );
    },
    qrCodeDialogClosed() {
      this.selectedLbGroupName = "";
    },
    // ==================== Update ShowScaReport =========================
    showScaReportConfirmDialog(lbGroupGuid, isShowScaReport) {
      this.selectedLbGroupGuid = lbGroupGuid;
      this.selectedIsShowScaReport = isShowScaReport;
      this.isShowScaReportConfirmDialog = true;
    },
    scaReportConfirmDialogCancelled() {
      // 取消 IsShowScaReport 的选择
      let cancelledItem = this.lbGroupList.find(
        lg => lg.guid === this.selectedLbGroupGuid
      );
      cancelledItem.isShowScaReport = false;
    },
    scaReportConfirmDialogClosed() {
      this.selectedLbGroupGuid = "";
      this.selectedIsShowScaReport = false;
    },
    async updateIsShowScaReportForLbGroup(
      lbGroupGuid,
      isShowScaReport,
      invokeFromDialog
    ) {
      // 从 false 改为 true 的时候需要弹窗警告
      // 反之则无需警告
      if (!invokeFromDialog && isShowScaReport) {
        this.showScaReportConfirmDialog(lbGroupGuid, true);
      } else {
        try {
          this.isLoading = true;
          this.isDialogLoading = true;
          await updateIsShowScaReportForLbGroup(lbGroupGuid, isShowScaReport);
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isShowScaReportConfirmDialog = false;
        this.isDialogLoading = false;
        this.isLoading = false;
      }
    },
    // ==================== Edit Lb Group Other Info =========================
    async showOtherInfoEditDialog(lbGroupItem) {
      this.assignLbGroupItem(lbGroupItem);
      this.isLoading = true;
      let lbGroupDetails = await this.getLbGroupDetails(
        this.selectedLbGroupGuid
      );
      this.editingLbGroupDesc = lbGroupDetails.lbGroupDesc;
      this.editingAllCompletedMsg = lbGroupDetails.allCompletedMsg;
      this.isLoading = false;
      this.isShowOtherInfoEditDialog = true;
    },
    async editOtherInfoConfirmed() {
      try {
        this.isDialogLoading = true;
        await updateOtherInfoForLbGroup(this.selectedLbGroupGuid, {
          lbGroupDesc: this.editingLbGroupDesc,
          allCompletedMsg: this.editingAllCompletedMsg
        });
        this.isShowOtherInfoEditDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    otherInfoEditDialogClosed() {
      this.resetLbGroupItem();
      this.editingLbGroupDesc = "";
      this.editingAllCompletedMsg = "";
    },
    // ==================== Delete Lb Group =========================
    showDeleteLbGroupDialog(lbGroupItem) {
      this.assignLbGroupItem(lbGroupItem);
      this.isShowDeleteLbGroupDialog = true;
    },
    deleteLbGroupDialogClosed() {
      this.resetLbGroupItem();
    },
    async deleteOneLbGroup() {
      try {
        this.isDialogLoading = true;
        let delGuid = await deleteOneLbGroup(this.selectedLbGroupGuid);
        this.spliceItemFromLbGroupList(delGuid);
        this.isShowDeleteLbGroupDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    showAddDialog() {
      if (this.isGroupType || this.isOpenType) {
        this.isShowLbGroupCreationDialog = true;
      } else {
        this.isShowIndividualCreationDialog = true;
      }
    }
  },

  async created() {
    this.isLoading = true;
    this.initNodeLbGroupHeaders();
    await this.prepareNodeList();
    await this.refreshLbGroupList();
    this.isLoading = false;
  }
};
</script>

<style lang="scss" scoped>
.fixed-card {
  height: calc(100vh - 125px);
}

.lbgroup-search-field {
  max-width: 300px;
}
</style>
