<template>
  <v-menu
    v-model="isShowMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="date-range-picker"
        :class="fieldClass"
        :value="dateRangeText"
        :prepend-inner-icon="prependInnerIcon"
        :label="label"
        :filled="filled"
        :outlined="outlined"
        :single-line="singleLine"
        :dense="dense"
        readonly
        hide-details="auto"
        :clearable="clearable"
        :rules="rules"
        v-on="on"
        v-bind="attrs"
        @click:clear="clearDateRange"
      >
        <template #append>
          <slot name="append"></slot>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-title>
        {{ label }}
        <v-spacer></v-spacer>
        <!-- 只有 Range 的情况下，才会有快速选择功能 -->
        <v-select
          v-if="useQuickSelect && isRange"
          class="px-4 preset-select"
          outlined
          dense
          hide-details="auto"
          label="快速选择"
          v-model="selectedPresetRange"
          :items="presetRangeItems"
          @change="setStartEndDateFromPresetItem(true)"
        ></v-select>
      </v-card-title>
      <v-date-picker
        v-if="onlyMonth"
        v-model="localDateRange"
        full-width
        no-title
        :range="isRange"
        show-adjacent-months
        locale="zh-cn"
        type="month"
        :min="dateMin"
        :max="dateMax"
        @change="datePickerSelectionChanged"
      ></v-date-picker>
      <v-date-picker
        v-else
        v-model="localDateRange"
        full-width
        no-title
        :range="isRange"
        show-adjacent-months
        locale="zh-cn"
        :day-format="d => d.split('-')[2]"
        :min="dateMin"
        :max="dateMax"
        @change="datePickerSelectionChanged"
      ></v-date-picker>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="clickCloseBtn">关闭</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import _ from "lodash";
import {
  presetDateRangeSelects,
  presetFutureDateRangeSelects,
  getStartEndDateFromPresetRange,
  getFutureStartEndDateFromPresetRange,
  getDateFromDateTime
} from "@/utils/dateTime";

export default {
  props: {
    onlyMonth: {
      type: Boolean,
      default: false
    },
    fieldClass: {
      type: String
    },
    prependInnerIcon: {
      type: String,
      default: "mdi-calendar-text"
    },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: "350px"
    },
    isRange: {
      type: Boolean,
      default: true
    },
    dateMin: {
      type: String
    },
    dateMax: {
      type: String
    },
    dateRange: {
      type: [Array, String],
      required: true
    },
    useQuickSelect: {
      type: Boolean,
      default: true
    },
    isFutureDate: {
      type: Boolean,
      default: false
    },
    initWithDefault: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    }
  },

  model: {
    prop: "dateRange",
    event: "changed"
  },

  data() {
    return {
      isShowMenu: false,
      selectedPresetRange: "",
      presetRangeItems: [],
      localDateRange: this.dateRange
    };
  },

  watch: {
    isShowMenu(newVal) {
      if (newVal) {
        this.initPresetRangeItems();
        this.initDefaultPresetRange();
      }
    },
    dateRange(newVal) {
      this.localDateRange = newVal;
    },
    localDateRange(newVal) {
      // 不添加此条件，会造成死循环
      newVal !== this.dateRange &&
        this.$emit("changed", this.rebuildDateRange(newVal));
    }
  },

  computed: {
    dateRangeText() {
      let dText = "";
      if (!this.isRange) {
        // 不是 Range 的时候，date 就是字符串
        dText = this.localDateRange;
      } else if (this.localDateRange.length === 1) {
        dText = getDateFromDateTime(this.localDateRange[0]);
      } else if (this.localDateRange.length === 2) {
        dText = `${getDateFromDateTime(
          this.localDateRange[0]
        )} ~ ${getDateFromDateTime(this.localDateRange[1])}`;
      }
      return dText;
    }
  },

  methods: {
    initPresetRangeItems() {
      if (this.isFutureDate) {
        this.presetRangeItems = presetFutureDateRangeSelects;
      } else {
        this.presetRangeItems = presetDateRangeSelects;
      }
    },
    initDefaultPresetRange() {
      if (this.initWithDefault) {
        this.selectedPresetRange = this.presetRangeItems.find(
          item => item.default
        );
        this.setStartEndDateFromPresetItem(false);
      }
    },
    setStartEndDateFromPresetItem(autoClose) {
      let startEndDate = {};
      if (this.isFutureDate) {
        startEndDate = getFutureStartEndDateFromPresetRange(
          this.selectedPresetRange
        );
      } else {
        startEndDate = getStartEndDateFromPresetRange(this.selectedPresetRange);
      }
      // 全为空表示“全部的时间”
      if (startEndDate.startDate && startEndDate.endDate) {
        this.localDateRange = [startEndDate.startDate, startEndDate.endDate];
      } else {
        this.localDateRange = [];
      }
      // 快速选择完自动关闭
      if (autoClose) {
        this.isShowMenu = false;
      }
    },
    datePickerSelectionChanged() {
      // 手动选择日期后，自动取消快速选择
      this.selectedPresetRange = "";
      // 单选日期时，选中日期完自动关闭
      if (!this.isRange) {
        this.isShowMenu = false;
      }
    },
    rebuildDateRange(dateRange) {
      if (this.isRange) {
        let newDateRange = [];
        if (dateRange.length === 1) {
          newDateRange = dateRange;
        } else if (dateRange.length === 2) {
          newDateRange = [_.min(dateRange), _.max(dateRange)];
        }
        return newDateRange;
      }
      return dateRange;
    },
    clearDateRange() {
      this.localDateRange = this.isRange ? [] : "";
      // 同时删除 preset 的选中值
      this.selectedPresetRange = "";
    },
    clickCloseBtn() {
      this.isShowMenu = false;
    }
  },

  created() {
    this.initPresetRangeItems();
    this.initDefaultPresetRange();
  }
};
</script>

<style lang="scss" scoped>
.date-range-picker {
  min-width: 290px;
}

.preset-select {
  max-width: 200px;
}
</style>
