export const configType = {
  text: {
    value: "text",
    text: "文本框",
    constraintCol: "strLength"
  },
  select: {
    value: "select",
    text: "选择项",
    constraintCol: "valueRange"
  },
  datetime: {
    value: "datetime",
    text: "日期框",
    constraintCol: ""
  },
  number: {
    value: "number",
    text: "数字框",
    constraintCol: "numRange"
  },
  multiselect: {
    value: "multiselect",
    text: "多选项",
    constraintCol: "valueRange"
  },
  time: {
    value: "time",
    text: "时间框",
    constraintCol: ""
  }
};

export const specialConfigField = {
  node: {
    fieldName: "nodeGuid",
    dispFieldName: "nodeName"
  },
  age: {
    fieldName: "age"
  },
  dateOfBirth: {
    fieldName: "dateOfBirth"
  }
};

export const personProfileDispConfigFields = [
  "nodeGuid",
  "name",
  "gender",
  "age"
];

export function personLoginIdentitySortFunc(id1, id2) {
  if (!id1) return -1;
  if (!id2) return 1;
  return id1.loginKey > id2.loginKey ? 1 : -1;
}
