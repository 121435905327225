import store from "@/store/index";

export function permArrayFunc() {
  return store.getters["user/permissions"];
}

export function casePagePermission(permission) {
  let isShow = true;
  if (permArrayFunc().indexOf("case_all") <= -1) {
    if (permArrayFunc().indexOf(permission) <= -1) {
      isShow = false;
    }
  }
  return isShow;
}

export function testPersonPagePermission(permission) {
  let isShow = true;
  if (permArrayFunc().indexOf("person_all") <= -1) {
    if (permArrayFunc().indexOf(permission) <= -1) {
      isShow = false;
    }
  }
  return isShow;
}

export function adminConfigNodePagePermission(permission) {
  let isShow = true;
  if (permArrayFunc().indexOf("node_all") <= -1) {
    if (permArrayFunc().indexOf(permission) <= -1) {
      isShow = false;
    }
  }
  return isShow;
}

export function lbGroupPagePermission(lbGroupType, permission) {
  let isShow = true;
  if (permArrayFunc().indexOf(lbGroupType) <= -1) {
    if (permArrayFunc().indexOf(permission) <= -1) {
      isShow = false;
    }
  }
  return isShow;
}

export function lbManagePagePermission(permission) {
  let isShow = true;
  if (permArrayFunc().indexOf("lb_all") <= -1) {
    if (permArrayFunc().indexOf(permission) <= -1) {
      isShow = false;
    }
  }
  return isShow;
}
