import { configType } from "@/utils/constants/fieldConfig";

export function buildAgeString({ year, month, day }) {
  let yearStr = year > -1 ? `${year}岁` : "";
  let monthStr = month > -1 ? `${month}月` : "";
  let dayStr = day > -1 ? `${day}天` : "";
  if (year >= Number(process.env.VUE_APP_NO_AGE_DAY_DISPLAY_THRESHOLD)) {
    dayStr = "";
  }
  return yearStr + monthStr + dayStr;
}

export function assignLoginKeyToPerson(personInfo, loginKey) {
  if (loginKey && personInfo && personInfo.loginIdentity) {
    personInfo.loginIdentity.loginKey = loginKey;
  }
}

export function reInitPersonInfoForEditing(personInfo, fieldConfig) {
  let multiSelectFieldsConfig = $_findSpecialProcessFields(
    personInfo,
    fieldConfig,
    configType.multiselect.value
  );
  for (let msField of multiSelectFieldsConfig) {
    personInfo[msField.fieldName] = $_splitMultiSelectValueToArray(
      personInfo[msField.fieldName]
    );
  }
}

export function reInitPersonInfoForDispAndSave(personInfo, fieldConfig) {
  let multiSelectFieldsConfig = $_findSpecialProcessFields(
    personInfo,
    fieldConfig,
    configType.multiselect.value
  );
  for (let msField of multiSelectFieldsConfig) {
    personInfo[msField.fieldName] = $_joinArrayToMultiSelectValue(
      personInfo[msField.fieldName]
    );
  }
}

function $_findSpecialProcessFields(personInfo, fieldConfig, fieldConfigType) {
  let allFieldNames = Object.keys(personInfo);
  return fieldConfig.filter(
    fc =>
      allFieldNames.includes(fc.fieldName) && fc.configType === fieldConfigType
  );
}

function $_splitMultiSelectValueToArray(value) {
  let outArray = [];
  if (value) {
    outArray = value.split("|");
  }
  return outArray;
}

function $_joinArrayToMultiSelectValue(array) {
  let outValue = "";
  if (array && typeof array === "object") {
    outValue = array.join("|");
  }
  return outValue;
}
